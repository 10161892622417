<template>
  <div class="article container">
    <div class="header">
      <div class="title-box">
        <p class="title">文章详情</p>
      </div>
    </div>
    <p class="title" v-if="data">{{ data.title }}</p>
    <p class="time" v-if="data">{{ get_time(data.created_at) }}</p>
    <div class="content" v-if="data" v-html="data.content"></div>
  </div>
</template>

<script>
export default {
  name: 'Article',
  props: ['id'],
  data () {
    return {
      data: null
    }
  },
  mounted () {
    this.requestGetArticle()
  },
  methods: {
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    get_time (t) {
      var time = new Date(parseInt(t) * 1000)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      var h = time.getHours()
      var mm = time.getMinutes()
      return y + '-' + this.add0(m) + '-' + this.add0(d) + ' ' + this.add0(h) + ':' + this.add0(mm)
    },
    requestGetArticle () {
      this.$loading.show()
      this.$api.articleDetail(this.id).then(res => {
        this.data = res.data
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.article {
  padding-top: 30px;
  padding-bottom: 40px;

  > .header {
    padding: 0 18px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    > .title-box {
      display: flex;
      align-items: center;
      height: 45px;

      > .title {
        font-size: 18px;
        font-weight: 500;
        color: #222;
      }
    }
  }

  > .title {
    padding-top: 40px;
    padding-bottom: 30px;
    font-size: 32px;
    font-weight: 400;
    color: #222;
    text-align: center;
    background-color: #fff;
  }

  > .time {
    font-size: 14px;
    color: #959595;
    text-align: center;
    background-color: #fff;
  }

  > .content {
    margin: 0;
    padding: 36px 30px;
    padding-top: 50px;
    font-size: 16px;
    line-height: 32px;
    word-break: break-all;
    word-wrap: break-word;
    text-align: justify;
	  text-justify: inter-word;
    background-color: #fff;
  }
}
</style>
